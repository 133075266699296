import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import { AuthContext } from '../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {
  const { authState } = useContext(AuthContext);
  const [estatisticas, setEstatisticas] = useState({
    metricas: {
      totalAlunos: 0,
      totalCursos: 0,
      cursosConcluidos: 0,
      cursosEmAndamento: 0,
      taxaConclusao: 0
    },
    faturamento: [{
      total: 0,
      distribuicao: {
        felipe: 0,
        amadeu: 0,
        matheus: 0,
        empresa: 0
      }
    }],
    statusAlunos: [],
    vendasPorCurso: []
  });
  const [periodoSelecionado, setPeriodoSelecionado] = useState(
    `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}`
  );

  // Card Total de Alunos
  const TotalAlunosCard = () => (
    <Card className="dashboard-card">
      <Card.Body>
        <Card.Title>Total de Alunos</Card.Title>
        <h2>{estatisticas.metricas.totalAlunos}</h2>
        <p>Alunos ativos no mês</p>
      </Card.Body>
    </Card>
  );

  // Card Taxa de Conclusão
  const TaxaConclusaoCard = () => (
    <Card className="dashboard-card">
      <Card.Body>
        <Card.Title>Taxa de Conclusão</Card.Title>
        <h2>{estatisticas.metricas.taxaConclusao}%</h2>
        <p>Cursos concluídos: {estatisticas.metricas.cursosConcluidos}</p>
        <p>Cursos em andamento: {estatisticas.metricas.cursosEmAndamento}</p>
      </Card.Body>
    </Card>
  );

  // Card Faturamento Total com Distribuição
  const FaturamentoCard = () => {
    const faturamentoTotal = estatisticas.faturamento[0]?.total || 0;
    const distribuicao = estatisticas.faturamento[0]?.distribuicao || {};

    return (
      <Card className="dashboard-card">
        <Card.Body>
          <Card.Title>Faturamento Total</Card.Title>
          <h2>
            {faturamentoTotal.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            })}
          </h2>
          
        </Card.Body>
      </Card>
    );
  };

  // Tabela Status dos Alunos
  const StatusAlunosTable = () => (
    <Card className="dashboard-card">
      <Card.Body>
        <Card.Title>Status dos Alunos</Card.Title>
        <Table responsive striped>
          <thead>
            <tr>
              <th>Aluno</th>
              <th>Curso</th>
              <th>Status</th>
              <th>Data Conclusão</th>
            </tr>
          </thead>
          <tbody>
            {estatisticas.statusAlunos.map((status, index) => (
              <tr key={index}>
                <td>{status.aluno_nome}</td>
                <td>{status.curso_nome}</td>
                <td>
                  <span className={`status-badge ${status.status.toLowerCase().replace(' ', '-')}`}>
                    {status.status}
                  </span>
                </td>
                <td>
                  {status.data_conclusao 
                    ? new Date(status.data_conclusao).toLocaleDateString('pt-BR')
                    : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );

  // Gráfico Vendas por Curso
  const VendasPorCursoCard = () => {
    const dados = {
      labels: estatisticas.vendasPorCurso.map(venda => venda.curso_nome),
      datasets: [{
        label: 'Total de Vendas',
        data: estatisticas.vendasPorCurso.map(venda => venda.total_vendas),
        backgroundColor: '#FF7F00'
      }]
    };

    return (
      <Card className="dashboard-card">
        <Card.Body>
          <Card.Title>Vendas por Curso</Card.Title>
          <Bar data={dados} />
          <Table responsive className="mt-3">
            <thead>
              <tr>
                <th>Curso</th>
                <th>Vendas</th>
                <th>Valor Total</th>
              </tr>
            </thead>
            <tbody>
              {estatisticas.vendasPorCurso.map((venda, index) => (
                <tr key={index}>
                  <td>{venda.curso_nome}</td>
                  <td>{venda.total_vendas}</td>
                  <td>
                    {venda.valor_total.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

  // Gráfico Distribuição do Faturamento
  const DistribuicaoFaturamentoCard = () => {
    const distribuicao = estatisticas.faturamento[0]?.distribuicao || {};
    const dados = {
      labels: ['Felipe (40%)', 'Amadeu (40%)', 'Matheus (10%)', 'Empresa (10%)'],
      datasets: [{
        data: [
          distribuicao.felipe || 0,
          distribuicao.amadeu || 0,
          distribuicao.matheus || 0,
          distribuicao.empresa || 0
        ],
        backgroundColor: ['#FF7F00', '#AA5400', '#070D14', '#15283E']
      }]
    };

    return (
      <Card className="dashboard-card">
        <Card.Body>
          <Card.Title>Distribuição do Faturamento</Card.Title>
          <Doughnut data={dados} />
        </Card.Body>
      </Card>
    );
  };

  useEffect(() => {
    const carregarEstatisticas = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/estatisticas-gerais/${periodoSelecionado}`,
          {
            headers: {
              'Authorization': `Bearer ${authState.token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        if (!response.ok) {
          throw new Error('Erro ao carregar estatísticas');
        }

        const data = await response.json();
        setEstatisticas(data);
      } catch (error) {
        console.error('Erro ao carregar estatísticas:', error);
        toast.error('Erro ao carregar estatísticas');
      }
    };

    if (authState?.token) {
      carregarEstatisticas();
    }
  }, [periodoSelecionado, authState]);

  return (
    <Container fluid>
      <ToastContainer />
      <Row className="mb-4">
        <Col>
          <input
            type="month"
            value={periodoSelecionado}
            onChange={(e) => setPeriodoSelecionado(e.target.value)}
            className="form-control"
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}><TotalAlunosCard /></Col>
        <Col md={4}><TaxaConclusaoCard /></Col>
        <Col md={4}><FaturamentoCard /></Col>
      </Row>
      <Row className="mt-4">
        <Col md={12}><StatusAlunosTable /></Col>
      </Row>
      <Row className="mt-4">
        <Col md={8}><VendasPorCursoCard /></Col>
        <Col md={4}><DistribuicaoFaturamentoCard /></Col>
      </Row>
    </Container>
  );
};

export default Dashboard;