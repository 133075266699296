import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [authState, setAuthState] = useState(() => {
    const token = localStorage.getItem('token');
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    return {
      token: token || null,
      role: userDetails?.role || null,
      username: userDetails?.username || null,
      userId: userDetails?.userId || null,
      empresa: userDetails?.empresa || null
    };
  });

  const setAuthInfo = (authInfo) => {
    localStorage.setItem('token', authInfo.token);
    localStorage.setItem('userDetails', JSON.stringify({
      role: authInfo.role,
      username: authInfo.username,
      userId: authInfo.userId,
      empresa: authInfo.empresa
    }));
    setAuthState(authInfo);
  };

  const clearAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userDetails');
    setAuthState({
      token: null,
      role: null,
      username: null,
      userId: null,
      empresa: null
    });
    history.push('/login');
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthInfo, clearAuth }}>
      {children}
    </AuthContext.Provider>
  );
};