import Dashboard from "./views/Dashboard.js";
import AdicionarAlunos from "./views/NovoAluno.js";
import Cursos from "./views/Cursos.js";
import PainelUsuario from "./views/PainelUsuario.js";
import Config from "./views/Configuracoes.js";
import User from "./views/UserProfile.js";
import DemoPage from "./views/DemoPage.js";
import Financeiro from "./views/Financeiro.js";
import PaginaCertificados from "./views/PaginaCertificados.js";
import GerenciamentoCursos from "./views/GerenciamentoCursos.js";
import PainelEmpresa from "./views/PainelEmpresa.js";
import AlunoEmpresa from "./views/AlunoEmpresa.js";
import CursosEmpresas from "./views/CursosEmpresas.js";
import DashboardEmpresa from "./views/DashboardEmpresa.js";


const dashboardRoutes = [
  {
    path: "/painel",
    name: "Painel",
    icon: "nc-icon nc-chart-bar-32",
    component: DashboardEmpresa,
    layout: "/empresa",
    roles: ['Empresa'],
    default: true
  },
  {
    name: "Cadastro",
    icon: "nc-icon nc-single-02",
    layout: "/empresa", // Layout da empresa
    path: "/alunos", // Rota para a página AlunoEmpresa.js
    component: AlunoEmpresa,
    roles: ['Empresa'], // Role da empresa
  },
  {
    path: "/cursos",
    name: "Cursos",
    icon: "nc-icon nc-ruler-pencil",
    component: CursosEmpresas,
    layout: "/empresa",
    roles: ['Empresa']
  },

 

  {
    path: "/dashboard",
    name: "Painel",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
    roles: ['Admin']
  },
  
  {
    path: "/LojaCursos",
    name: "Loja Cursos",
    component: Cursos,
    icon: "nc-icon nc-ruler-pencil",
    layout: "/usuario",
    roles: ['Aluno'],
   
  },

  {
    path: "/central",
    name: "Central Aluno",
    component: PainelUsuario,
    layout: "/usuario",
    icon: " nc-icon nc-spaceship",
    roles: ['Aluno']
  },
  {
    name: "Gerenciamento",
    icon: "nc-icon nc-single-02",
    layout: "/admin",
    path: "/GerenciamentoGeral",
    component: AdicionarAlunos,
    roles: ['Admin'], 
  },
  /*
  {
    name: "Instituições",
    icon: "nc-icon nc-bank",
    layout: "/admin",
    roles: ['Dev'], 
    subMenu: [
      {
        
        path: "/NovaInstituicao",
        name: "Nova Instituição",
        component: NR4,
        layout: "/admin",
        roles: ['Dev'],
      
      },
      {
        path: "/GerenciarInstituicao",
        name: "Gerenciar Instituição",
        component: GerenciamentoInstituicao, // Import this at the top of the file
        layout: "/admin",
        roles: [ 'Dev'],
      },
    ]
  },
  */
  {
    
    path: "/GerenciarCursos",
    name: "Cursos",
    component: GerenciamentoCursos,
    icon: "nc-icon nc-bank",
    layout: "/admin",
    roles: ['Admin'],
   
  },
  {
        
    path: "/Perfil",
    name: "Meu Perfil",
    component: User,
    icon: "nc-icon nc-badge",
    layout: "/usuario",
    roles: ['Aluno'],
      
  },
   
 
  {
    path: "/certificados",
    name: "Certificados",
    component: PaginaCertificados,
    icon: "nc-icon nc-single-copy-04",
    layout: "/usuario",
    roles: ['Aluno'],
  },
  

];

export default dashboardRoutes;