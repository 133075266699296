import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, Table, ProgressBar, Tabs, Tab, Badge, Form, Button, Pagination, Spinner } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import { FaGraduationCap, FaUsers, FaChartLine, FaExclamationTriangle, FaCheckCircle, FaClock, FaMoneyBillWave, FaCalendarAlt, FaChartBar, FaUserGraduate, FaSearch, FaFileDownload } from 'react-icons/fa';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';
import './DashboardEmpresa.scss';

const DashboardEmpresa = () => {
  const [statusFilter, setStatusFilter] = useState('todos');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [estatisticas, setEstatisticas] = useState({
    statusAlunos: [],
    cursosAtivos: 0,
    cursosConcluidos: 0,
    totalAlunos: 0,
    taxa_conclusao: 0
  });

  const { authState } = useContext(AuthContext);

  const fetchAlunosStatus = async (status = 'todos') => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/empresa/alunos-status/${encodeURIComponent(authState.username)}/${status}`,
        {
          headers: {
            Authorization: `Bearer ${authState.token}`
          }
        }
      );
      
      if (response.data.success) {
        const alunosData = response.data.data;
        
        // Calcular estatísticas
        const cursosAtivos = alunosData.filter(a => a.status_progresso === 'Em Andamento').length;
        const cursosConcluidos = alunosData.filter(a => a.status_progresso === 'Concluído').length;
        const totalAlunos = new Set(alunosData.map(a => a.aluno_nome)).size;
        const taxa_conclusao = cursosAtivos + cursosConcluidos > 0 ? 
          ((cursosConcluidos / (cursosAtivos + cursosConcluidos)) * 100).toFixed(1) : '0';

        setEstatisticas({
          statusAlunos: alunosData,
          cursosAtivos,
          cursosConcluidos,
          totalAlunos,
          taxa_conclusao
        });
      }
    } catch (error) {
      console.error('Erro ao buscar status dos alunos:', error);
      toast.error('Erro ao carregar dados dos alunos');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authState.token) {
      fetchAlunosStatus(statusFilter);
    }
  }, [authState.token, statusFilter]);

  // Função para filtrar alunos por nome
  const filteredAlunos = estatisticas.statusAlunos.filter(aluno =>
    aluno.aluno_nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Configuração dos gráficos
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      }
    }
  };

  // Dados para o gráfico de distribuição de status
  const statusDistributionData = {
    labels: ['Concluído', 'Em Andamento', 'Não Iniciado'],
    datasets: [{
      data: [
        estatisticas.statusAlunos.filter(a => a.status_progresso === 'Concluído').length || 0,
        estatisticas.statusAlunos.filter(a => a.status_progresso === 'Em Andamento').length || 0,
        estatisticas.statusAlunos.filter(a => a.status_progresso === 'Não Iniciado').length || 0
      ],
      backgroundColor: ['#28a745', '#ffc107', '#dc3545']
    }]
  };

  // Novo componente para cards de métricas avançadas
  const MetricCard = ({ icon: Icon, title, value, description, color }) => (
    <Card className="metric-card">
      <Card.Body>
        <div className="metric-header">
          <Icon size={24} color={color} />
          <h3>{value}</h3>
        </div>
        <div className="metric-footer">
          <span className="title">{title}</span>
          <span className="description">{description}</span>
        </div>
      </Card.Body>
    </Card>
  );

  const handleExport = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/empresa/export-alunos/${encodeURIComponent(authState.username)}/${statusFilter}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${authState.token}`
        }
      });

      // Criar URL do blob e fazer download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `relatorio-alunos-${new Date().toISOString().split('T')[0]}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Erro ao exportar dados:', error);
      toast.error('Erro ao gerar relatório');
    }
  };

  return (
    <Container fluid className="dashboard-container">
      {/* Cabeçalho */}
      <div className="dashboard-header">
        <h1>Dashboard Empresarial</h1>
        <p className="text-muted">Visão geral dos cursos e desempenho dos alunos</p>
      </div>

      {/* Cards Principais */}
      <Row className="g-3 mb-4">
        <Col md={3}>
          <MetricCard 
            icon={FaUsers}
            title="Total de Alunos"
            value={estatisticas.totalAlunos}
            description="Alunos ativos na plataforma"
            color="#14253A"
          />
        </Col>
        <Col md={3}>
          <MetricCard 
            icon={FaGraduationCap}
            title="Cursos Ativos"
            value={estatisticas.cursosAtivos}
            description="Em andamento"
            color="#ffc107"
          />
        </Col>
        <Col md={3}>
          <MetricCard 
            icon={FaCheckCircle}
            title="Cursos Concluídos"
            value={estatisticas.cursosConcluidos}
            description="Total"
            color="#28a745"
          />
        </Col>
        <Col md={3}>
          <MetricCard 
            icon={FaChartLine}
            title="Taxa de Conclusão"
            value={`${estatisticas.taxa_conclusao}%`}
            description="Média geral"
            color="#ffc107"
          />
        </Col>
      </Row>

     
    

      {/* Tabela de Alunos */}
      <Card className="enhanced-table-card">
        <Card.Header>
          <div className="table-header">
            <div className="header-title">
              <h5>Status dos Alunos</h5>
              <span className="subtitle">Gestão e acompanhamento de progresso</span>
            </div>
            <div className="table-actions">
              <div className="search-filter-group">
                <div className="status-filter-wrapper">
                  <Form.Select 
                    className="status-filter"
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  >
                    <option value="todos">Todos os Status</option>
                    <option value="Concluído">Concluído</option>
                    <option value="Em Andamento">Em Andamento</option>
                    <option value="Não Iniciado">Não Iniciado</option>
                  </Form.Select>
                </div>
                <div className="search-wrapper">
                  <FaSearch className="search-icon" />
                  <Form.Control 
                    type="search" 
                    placeholder="Buscar aluno..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <Button 
                variant="primary" 
                className="export-button"
                onClick={handleExport}
              >
                <FaFileDownload className="me-2" />
                Exportar Relatório Geral
              </Button>
            </div>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {loading ? (
            <div className="table-loading">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="table-responsive">
              <Table className="custom-table">
                <thead>
                  <tr>
                    <th>Aluno</th>
                    <th>Curso</th>
                    <th className="text-center">Status</th>
                    <th>Data Conclusão</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAlunos.map((aluno, idx) => (
                    <tr key={idx}>
                      <td data-label="Aluno">
                        <div className="aluno-info">
                          <div className="avatar">
                            {aluno.aluno_nome.charAt(0)}
                          </div>
                          <span>{aluno.aluno_nome}</span>
                        </div>
                      </td>
                      <td data-label="Curso">{aluno.curso_nome}</td>
                      <td data-label="Status" className="text-center">
                        <Badge className={`status-badge-enhanced ${aluno.status_progresso.toLowerCase()}`}>
                          <div className="status-icon"></div>
                          {aluno.status_progresso}
                        </Badge>
                      </td>
                      <td data-label="Conclusão">
                        {aluno.data_conclusao ? 
                          new Date(aluno.data_conclusao).toLocaleDateString('pt-BR') : 
                          '-'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Card.Body>
      </Card>

    </Container>
  );
};

export default DashboardEmpresa; 